import { ListingMatch } from '@deltek/specification-client/core/Models/listingSearch'
import GridItem from '@deltek/specification-client/core/Surfaces/GridItem'
import {
    groupByListings,
    ListingMatchLeaf,
    TreeNode,
} from '@deltek/specification-client/core/Widgets/ProductListing/groupedTreeUtils'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useRef, useState } from 'react'
import { VariableSizeNodeData, VariableSizeTree as Tree } from 'react-vtree'
import { AccordionWindowNode } from './AccordionWindowNode'

export interface AccordionWindowProps {
    searchLoading?: boolean
    listingCount: number
    listings: Partial<ListingMatch>[]
    onLoadMore: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    groupBy: Record<string, boolean | string>
    height?: number
    width?: number
}

export interface TreeWalkerStack {
    nestingLevel: number
    node: TreeNode
}

type ExtendedTreeData = VariableSizeNodeData &
    Readonly<{
        productCount: number
        listingCount: number
        isLeaf?: boolean
        nestingLevel?: number
        margin?: number
        name?: string
        listingLeaf?: ListingMatchLeaf
        count?: number
        searchLoading?: boolean
        onLoadMore: (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => void
    }>

const useStyles = makeStyles(() => ({
    accordionTree: {
        '&>div': {
            overflow: 'hidden',
            position: 'relative',
        },
    },
}))
const cardHeight = 427
const accordionHeight = 52
const LoadmoreButtonHeight = 72
const loadMore = 'spLoadMore'
const AccordionWindow = ({
    searchLoading,
    listingCount,
    listings,
    groupBy,
    onLoadMore: loadMoreHandler,
    height,
    width,
}: AccordionWindowProps) => {
    const classes = useStyles()
    const [groupedListings, setGroupedListings] = useState<TreeNode>()
    const lastListingIdRef = useRef<string>('')
    const listingsRef = useRef<Partial<ListingMatch>[]>([])
    const lastListingsRef = useRef<Partial<ListingMatch>[]>([])
    const groupingRef = useRef<Record<string, boolean | string>>({})
    const lastGroupingRef = useRef<Record<string, boolean | string>>({})
    const isLoadMoreActiveRef = useRef<boolean>(false)
    const totalListingRef = useRef<number>(0)
    const treeRef = useRef<HTMLElement>()

    const onLoadMore = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        isLoadMoreActiveRef.current = true
        loadMoreHandler(event)
    }

    useEffect(() => {
        if (!listings || listings.length === 0) return
        const totalListings = listings.length
        const currentLastListingId = listings[totalListings - 1].listingId ?? ''
        const currentGroupingRef = groupBy
        if (!Object.values(currentGroupingRef).includes(loadMore)) {
            lastGroupingRef.current = currentGroupingRef
            ;(treeRef.current as HTMLElement)?.scrollTo(0, 0)
            isLoadMoreActiveRef.current = false
        }
        if (
            Object.values(currentGroupingRef).includes(loadMore) &&
            currentLastListingId !== lastListingIdRef.current
        ) {
            listingsRef.current = [...listingsRef.current, ...listings]
        } else listingsRef.current = [...listings]
        const updatedGroupedListings: TreeNode = {
            ...groupByListings({
                listings: listingsRef.current,
                groupBy: lastGroupingRef.current,
                listingCount,
            }),
            ...(({
                spLoadMore: {
                    id: loadMore,
                    name: loadMore,
                    count: 0,
                } as TreeNode,
            } as unknown) as TreeNode),
        }
        lastListingsRef.current = [...listings]
        setGroupedListings(updatedGroupedListings)
        groupingRef.current = currentGroupingRef
        lastListingIdRef.current = currentLastListingId
        totalListingRef.current = totalListings
    }, [listings, groupBy])

    function* treeWalker(
        refresh: boolean
    ): Generator<ExtendedTreeData | string | symbol, void, boolean> {
        if (!groupedListings) return
        const productCount = new Set<string>(
            listingsRef?.current?.map(
                (l: Partial<ListingMatch>) => l.listingId
            ) as string[]
        ).size
        const stack: TreeWalkerStack[] = Object.keys(groupedListings)
            .reverse()
            .map((topLevelKey) => ({
                nestingLevel: 0,
                node: groupedListings[topLevelKey] as TreeNode,
            }))

        while (stack.length !== 0) {
            const { node, nestingLevel } = stack.pop() as TreeWalkerStack
            const { name, isCollapsed } = node
            const nodeKeys = Object.keys(node)
            const isLeaf = nodeKeys.includes('isLeaf')
            const listingLeaf = isLeaf
                ? ((node as unknown) as ListingMatchLeaf)
                : undefined

            const id = !isLeaf ? node.id : listingLeaf?.id ?? ''
            const lineCount = listingLeaf
                ? Math.ceil(
                      listingLeaf?.groupedProduct?.length /
                          Math.floor(window.innerWidth / 380)
                  )
                : 1
            const isOpened = yield refresh
                ? {
                      productCount,
                      defaultHeight: isLeaf
                          ? lineCount * cardHeight
                          : name !== loadMore
                          ? accordionHeight
                          : LoadmoreButtonHeight,
                      isLeaf,
                      isOpenByDefault: !isCollapsed,
                      nestingLevel,
                      margin: nestingLevel * (20 * lineCount),
                      name,
                      listingLeaf,
                      onLoadMore,
                      count: isLeaf ? 0 : node.count,
                      id,
                      listingCount,
                      searchLoading,
                  }
                : id
            if (!isLeaf && isOpened) {
                Object.keys(node)
                    .reverse()
                    .forEach((nodeKey) => {
                        if (
                            nodeKey === 'name' ||
                            nodeKey === 'id' ||
                            nodeKey === 'count' ||
                            nodeKey === 'isCollapsed' ||
                            nodeKey === 'groupName'
                        )
                            return
                        stack.push({
                            nestingLevel: nestingLevel + 1,
                            node: node[nodeKey] as TreeNode,
                        })
                    })
            }
        }
    }

    return (
        <GridItem data-testid="accordionTree" xs={12}>
            <Tree
                className={classes.accordionTree}
                treeWalker={treeWalker}
                height={height ?? 800}
                width={width ?? '100%'}
                initialScrollOffset={0}
                outerRef={treeRef}
            >
                {AccordionWindowNode}
            </Tree>
        </GridItem>
    )
}

export default AccordionWindow
